import { useEffect, useState, useMemo } from 'react';
import { Tokenizer, ICardTokenData } from 'bank131-widgets';

import Loader from 'epn-ui/umd/dist/components/Loader';
import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';
import Grid from 'epn-ui/umd/dist/components/Grid';
import notification from 'epn-ui/umd/dist/components/Notification';
import BaseSanitizer from '@components/base/BaseSanitizer';

import useI18n from '@i18n';

// Helpers
import api from '@api';
import { cardExpValidation, isNumber } from '../../WalletsForm/utils';
import { IWalletTemplateForm } from '../interfaces';

// Config
import { regexpValidation, RENAME_ERRORS_BY_TYPE } from '../../WalletsForm/config';

import 'bank131-widgets/dist/style.css';

const isDev =
    typeof window !== 'undefined' &&
    (window.location.hostname.includes('epndev') || window.location.hostname.includes('localhost'));

type IBank131RfTemplate = Pick<
    IWalletTemplateForm,
    'form' | 'isLoadingTaxData' | 'taxData' | 'isOnlyForWireTransfer'
>;

export interface IGetToken {
    type: string;
    id: string;
    attributes: {
        token: string;
    };
}

const Bank131RfTemplate: React.FC<IBank131RfTemplate> = ({
    form,
    isLoadingTaxData,
    taxData,
    isOnlyForWireTransfer,
}) => {
    const [bankToken, setBankToken] = useState<string | null>(null);
    const { t } = useI18n();

    const preloadedCardHolderName =
        !isLoadingTaxData &&
        !isOnlyForWireTransfer &&
        taxData &&
        taxData[0]?.lastNameEn &&
        taxData[0]?.firstNameEn &&
        `${taxData[0].lastNameEn} ${taxData[0].firstNameEn} ${
            taxData[0].patronymicEn || ''
        }`.trim();

    if (preloadedCardHolderName) {
        form.setFieldsValue({ userName: preloadedCardHolderName });
    }

    const getToken = async () => {
        const request = await api.bank131.getToken<IGetToken>({});
        if (!request.result) {
            if (request.errors) {
                if (Array.isArray(request.errors)) {
                    request.errors.forEach(errorObject => {
                        notification.error({
                            message: `${errorObject.error} ${t(errorObject.error_description)}`,
                        });
                    });
                    return;
                }
            }
            notification.error({
                message: t('An error has occurred. Please write to tech support'),
            });
            console.error(request.errors);
            return;
        }

        if (request.data.attributes.token) {
            setBankToken(request.data.attributes.token);
        }
    };

    useEffect(() => {
        getToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFail = errorRes => {
        if (errorRes.data) {
            if (errorRes.data.error.code === 'public_token_invalid') {
                getToken();
            }
            notification.error({
                message:
                    errorRes.data.error.description ||
                    t('An error has occurred. Please write to tech support'),
            });
        }
    };

    const onSuccess = (cardToken: ICardTokenData) => {
        form.setFieldsValue({
            masked_card_number: cardToken?.info?.masked_card_number || '',
            card_network: cardToken?.info?.card_network || '',
            brand: cardToken?.info?.card_type || '',
            token: cardToken?.token || '',
        });
        form.submit();
    };

    const MemWidget = useMemo(
        () => (
            <Tokenizer
                token={bankToken}
                environment={isDev ? 'test' : 'production'}
                options={{
                    texts: {
                        cardNumberLabel: t('Card number'),
                        submitButtonLabel: t('Add wallet'),
                    },
                }}
                onTokenizationSuccess={onSuccess}
                onTokenizationFail={onFail}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bankToken],
    );

    if (!bankToken) return <Loader />;

    return (
        <>
            <Grid gutter={[16, 0]}>
                <Grid.Col md={24}>
                    <Form.Item
                        name="walletName"
                        label={t('Purse name')}
                        rules={[
                            {
                                validator: async (
                                    _rule: Rule,
                                    value: string | null | undefined,
                                ) => {
                                    if (isNumber(value)) {
                                        throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                                    }
                                },
                                validateTrigger: 'onBlur',
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletPurseNameInput"
                            placeholder={t('Enter purse name')}
                            maxLength={50}
                            theme="bordered"
                        />
                    </Form.Item>
                </Grid.Col>
                <Grid.Col md={19}>
                    <Form.Item
                        label={t('Cardholders name')}
                        name="userName"
                        rules={[
                            {
                                required: true,
                                message: t('Required field'),
                                validateTrigger: 'onBlur',
                            },
                            {
                                pattern: regexpValidation.cardHoldersName,
                                message: t(
                                    'Enter your full name. Only Latin characters, hyphen and space are allowed. Maximum 100 characters',
                                ),
                            },
                            {
                                max: 100,
                                message: t('Maximum length of characters', { max: 100 }),
                            },
                        ]}
                        validateTrigger="onBlur"
                    >
                        <InputText
                            id="ModalAddWalletCardOwnerInput"
                            placeholder={t('Enter the cardholders full name')}
                            theme="bordered"
                            disabled={!isOnlyForWireTransfer}
                        />
                    </Form.Item>
                    {taxData && !isOnlyForWireTransfer && (
                        <BaseSanitizer
                            html={t('preloaded tax data userName suggest')}
                            tag="p"
                            options={{ allowedTags: ['a'] }}
                            className="mb-4 text-p3 mt-[-15px] text-blueGray-300"
                        />
                    )}
                </Grid.Col>
                <Grid.Col md={5}>
                    <Form.Item
                        label={t('MM/YY')}
                        name="cardExpire"
                        rules={[
                            {
                                validator: async (
                                    _rule: Rule,
                                    value: string | null | undefined,
                                ) => {
                                    if (value === null || value === undefined || value === '') {
                                        throw new Error(`${t('Required field')}`);
                                    }

                                    if (!regexpValidation.cardExpire.test(value)) {
                                        throw new Error(t('Invalid entire period of the card'));
                                    }

                                    const result: string | boolean = cardExpValidation(value, t);
                                    if (typeof result !== 'boolean') {
                                        throw new Error(result);
                                    }
                                },
                                validateTrigger: 'onBlur',
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletCardExpireInput"
                            maxLength={5}
                            mask="00{/}00"
                            theme="bordered"
                        />
                    </Form.Item>
                </Grid.Col>
            </Grid>
            <Form.Item name="masked_card_number" className="hidden">
                <InputText type="hidden" />
            </Form.Item>

            <Form.Item name="card_network" className="hidden">
                <InputText type="hidden" />
            </Form.Item>

            <Form.Item name="brand" className="hidden">
                <InputText type="hidden" />
            </Form.Item>

            <Form.Item name="token" className="hidden">
                <InputText type="hidden" />
            </Form.Item>

            {MemWidget}
            <div className="mb-4 text-blueGray-600 text-p2">{t('Only cards of Russian banks')}</div>
        </>
    );
};

export default Bank131RfTemplate;
