import React, { useState, useMemo, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

import useI18n from '@i18n';

import { IGetUserProfileDataFormatted } from '@api/types';

import apiHooks from '@apiHooks';

interface IDynamicModals {
    onClose: () => void;
}

interface IBasePopupsManager {
    component: React.ComponentType<IDynamicModals>;
    condition: boolean;
}

// Helpers
const { useUserProfile } = apiHooks;

const BasePopupsManager: React.FC = () => {
    const [VisiblePopup, setVisiblePopup] = useState<null | IBasePopupsManager['component']>(null);
    const { data: userData } = useUserProfile<IGetUserProfileDataFormatted>();

    const { locale } = useI18n();
    const { query } = useRouter();

    const POPUPS_MAP: IBasePopupsManager[] = useMemo(
        () => [
            {
                component: dynamic<IDynamicModals>(() => import('./popups/OrdPopup'), {
                    ssr: false,
                }),
                condition: (() => {
                    const hasAllowedPlacement = userData?.wmOnboarding?.hasAllowedPlacement;
                    const hasOrdData = userData?.hasOrdData;
                    const isShowed = !!Cookies.get('OrdPopup');
                    const isImportant = query?.modal === 'ord';
                    // if (hasAllowedPlacement && !hasOrdData && !isShowed) {
                    //     return true;
                    // }
                    // // Добавление к ссылке параметра ?modal=ord, открывает модалку, если есть площадки
                    // if (hasAllowedPlacement && isImportant) {
                    //     return true;
                    // }
                    return false;
                })(),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userData, locale, query],
    );

    useEffect(() => {
        POPUPS_MAP.some(popup => {
            if (popup.condition) {
                setVisiblePopup(popup.component);
                return true;
            }
            return false;
        });
    }, [POPUPS_MAP]);

    const onClose = () => {
        setVisiblePopup(null);
    };

    if (!VisiblePopup) return null;

    return <VisiblePopup onClose={onClose} />;
};

export default BasePopupsManager;
