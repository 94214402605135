import { useMemo } from 'react';
import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';
import Select from 'epn-ui/umd/dist/components/Select';
import Grid from 'epn-ui/umd/dist/components/Grid';
import Empty from 'epn-ui/umd/dist/components/Empty';
import Skeleton from 'epn-ui/umd/dist/components/Skeleton';

import BaseSanitizer from '@components/base/BaseSanitizer';

// Config
import { RENAME_ERRORS_BY_TYPE } from '@pages/dashboard/Wallets/WalletsForm/config';

// Helpers
import useI18n from '@i18n';
import { LATIN_PATTERN } from '@utils/regex';
import { isNumber } from '../../WalletsForm/utils';
import { IWalletTemplateCountries, IWalletTemplateForm } from '../interfaces';
import VALIDATORS from './validators';

export interface IWiretransferTemplateProps
    extends Pick<
        IWalletTemplateForm,
        'countries' | 'countriesIsLoading' | 'id' | 'bankCountry' | 'form' | 'preloadedRecipient'
    > {
    defaultValues: {
        walletName: string;
        recipent: string;
        recipentCountry: string | null;
        recipentСity: string | null;
        recipentAddress: string;
        bankName: string;
        bankCountry: string | null;
        bankCity: string | null;
        bankAddress: string;
        iban: string;
        swift: string;
    };
    recipentCities?: IWalletTemplateCountries[];
    bankCities?: IWalletTemplateCountries[];
    citiesIsLoading: boolean;
}

const WireTransferTemplate: React.FC<IWiretransferTemplateProps> = ({
    countries = [],
    recipentCities = [],
    bankCities = [],
    id,
    defaultValues,
    countriesIsLoading,
    citiesIsLoading,
    bankCountry,
    preloadedRecipient,
    form,
}) => {
    const { t } = useI18n();

    if (preloadedRecipient) {
        form.setFieldsValue({ recipent: preloadedRecipient });
    }

    const recipientCountriesWithId = useMemo(() => {
        if (!Array.isArray(countries)) return [];
        if (countries.length === 0) return [];

        return countries.map(country => ({
            ...country,
            id: `RecipentCountrySelect_option_${country.value}`,
        }));
    }, [countries]);

    const bankCountriesWithId = useMemo(() => {
        if (!Array.isArray(countries)) return [];
        if (countries.length === 0) return [];

        return countries.map(country => ({
            ...country,
            id: `BankCountrySelect_option_${country.value}`,
        }));
    }, [countries]);

    const recipientCitiesWithId = useMemo(() => {
        if (!Array.isArray(recipentCities)) return [];
        if (recipentCities.length === 0) return [];

        return recipentCities.map(city => ({
            ...city,
            id: `RecipentСitySelect_option_${city.value}`,
        }));
    }, [recipentCities]);

    const bankCitiesWithId = useMemo(() => {
        if (!Array.isArray(bankCities)) return [];
        if (bankCities.length === 0) return [];

        return bankCities.map(city => ({
            ...city,
            id: `BankCitySelect_option_${city.value}`,
        }));
    }, [bankCities]);

    return (
        <>
            <Form.Item
                label={t('Purse name')}
                name="walletName"
                rules={[
                    {
                        pattern: LATIN_PATTERN,
                        message: t('Only Latin characters'),
                    },
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            // если имя уже было добавлено (есть defaultValues.walletName), то его нельзя сбросить
                            // eslint-disable-next-line no-extra-boolean-cast
                            if (!!defaultValues.walletName) {
                                if (value === null || value === undefined || value === '') {
                                    throw new Error(`${t('Required field')}`);
                                }
                            }

                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletPurseNameInput"
                    placeholder={t('Enter purse name')}
                    maxLength={50}
                />
            </Form.Item>

            <Form.Item
                label={t('Bank Account Name (Beneficiary)')}
                name="recipent"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: VALIDATORS.recipent,
                        message: `${t(
                            'Only Latin characters, numbers, and punctuation are allowed',
                            { symbols: '() ",._-"' },
                        )}\n${t(
                            'Account Holder/Benificiary Name must contain between 5 and 64 characters',
                        )}`,
                    },
                ]}
            >
                <InputText
                    id={`${id}RecipentInput`}
                    placeholder={t('Enter Bank Account Name (Beneficiary)')}
                    disabled={!!preloadedRecipient}
                />
            </Form.Item>
            {preloadedRecipient && (
                <BaseSanitizer
                    html={t('preloaded tax data userName suggest')}
                    tag="p"
                    options={{ allowedTags: ['a'] }}
                    className="mb-4 text-p3 mt-[-15px] text-blueGray-300"
                />
            )}

            <Form.Item
                label={t('Beneficiary country')}
                name="recipentCountry"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <Select
                    loading={countriesIsLoading}
                    disabled={countriesIsLoading}
                    id={`${id}RecipentCountrySelect`}
                    placeholder={t('Choose country')}
                    options={recipientCountriesWithId}
                />
            </Form.Item>

            <Form.Item
                label={t('Beneficiary city')}
                name="recipentСity"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <Select
                    loading={citiesIsLoading}
                    disabled={citiesIsLoading}
                    id={`${id}RecipentСitySelect`}
                    placeholder={t('Choose city')}
                    options={recipientCitiesWithId}
                    notFoundContent={<Empty description={t('Choose country')} />}
                />
            </Form.Item>

            <Form.Item
                label={t('Beneficiary address')}
                name="recipentAddress"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: VALIDATORS.recipentAddress,
                        message: `${t(
                            'Only Latin characters, numbers, and punctuation are allowed',
                            { symbols: '() ",._-/"' },
                        )}\n${t(
                            'Account Holder/Benificiary Address must contain between 10 and 256 characters',
                        )}`,
                    },
                ]}
            >
                <InputText
                    id={`${id}RecipentAddressInput`}
                    placeholder={t('Enter beneficiary address')}
                />
            </Form.Item>

            <Form.Item
                label={t('Beneficiary Bank')}
                name="bankName"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: VALIDATORS.bankName,
                        message: `${t(
                            'Only Latin characters, numbers, and punctuation are allowed',
                            { symbols: '() ",._-"' },
                        )}\n${t('Bank Name must contain between 4 and 70 characters')}`,
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText id={`${id}BankNameInput`} placeholder={t('Enter beneficiary Bank')} />
            </Form.Item>

            <Form.Item
                label={t('Bank country')}
                name="bankCountry"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <Select
                    loading={countriesIsLoading}
                    disabled={countriesIsLoading}
                    id={`${id}BankCountrySelect`}
                    placeholder={t('Choose country')}
                    options={bankCountriesWithId}
                />
            </Form.Item>

            <Form.Item
                label={t('Bank city')}
                name="bankCity"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <Select
                    loading={citiesIsLoading}
                    disabled={citiesIsLoading}
                    id={`${id}BankCitySelect`}
                    placeholder={t('Choose city')}
                    options={bankCitiesWithId}
                    notFoundContent={<Empty description={t('Choose country')} />}
                />
            </Form.Item>

            <Form.Item
                label={t('Bank address')}
                name="bankAddress"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    {
                        pattern: VALIDATORS.bankAddress,
                        message: `${t(
                            'Only Latin characters, numbers, and punctuation are allowed',
                            { symbols: '() ",._-/"' },
                        )}\n${t('Bank Address must contain between 10 and 100 characters')}`,
                    },
                ]}
            >
                <InputText id={`${id}BankAddressInput`} placeholder={t('Enter bank address')} />
            </Form.Item>

            <Grid gutter={[16, 0]}>
                <Grid.Col xs={24} sm={18}>
                    <Form.Item
                        label={`IBAN (${t('Account number')})`}
                        name="iban"
                        rules={[
                            {
                                required: true,
                                message: t('Required field'),
                                validateTrigger: 'onBlur',
                            },
                            {
                                pattern: VALIDATORS.iban,
                                message: `${t(
                                    'Only symbols of Latin alphabet and numbers are allowed',
                                )}\n${t(
                                    'Account Number must contain between 5 and 30 characters.',
                                )}`,
                            },
                        ]}
                    >
                        <InputText id={`${id}IbanInput`} placeholder={`${t('Enter value')} IBAN`} />
                    </Form.Item>
                </Grid.Col>

                <Grid.Col xs={24} sm={6}>
                    <Skeleton
                        loading={!bankCountry}
                        shape="square"
                        title={{
                            style: { width: '100%', height: '63px' },
                        }}
                    >
                        {bankCountry === 'RU' ? (
                            <Form.Item
                                label="BIC"
                                name="bic"
                                rules={[
                                    {
                                        required: true,
                                        message: t('Required field'),
                                        validateTrigger: 'onBlur',
                                    },
                                    {
                                        pattern: VALIDATORS.bic,
                                        message: `${t(
                                            'Only Latin characters and numbers are allowed. BIC code must contain from 8 to 11 characters',
                                        )}`,
                                    },
                                ]}
                            >
                                <InputText id={`${id}SwiftInput`} placeholder="BIC" />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                label="SWIFT"
                                name="swift"
                                rules={[
                                    {
                                        required: true,
                                        message: t('Required field'),
                                        validateTrigger: 'onBlur',
                                    },
                                    {
                                        pattern: VALIDATORS.swift,
                                        message: `${t(
                                            'Only Latin characters, numbers, and punctuation are allowed',
                                            { symbols: '() ",._-"' },
                                        )}\n${t(
                                            'SWIFT Code must contain between 5 and 20 characters',
                                        )}`,
                                    },
                                ]}
                            >
                                <InputText id={`${id}SwiftInput`} placeholder="SWIFT" />
                            </Form.Item>
                        )}
                    </Skeleton>
                </Grid.Col>
            </Grid>

            <div className="mb-4 text-blueGray-600 text-p2">
                {t(
                    'Payments are made twice a month, in the following periods: 1-3 and 16-18 of the month. If the period coincides with weekends or holidays, the period is moved forward to the next 3 working days.',
                )}
            </div>
            <div className="mb-4 text-blueGray-600 text-p2">
                {t(
                    'After ordering payment, contact your personal manager to prepare an invoice. Payments are made only after receiving an invoice for payment.',
                )}
            </div>
        </>
    );
};

export default WireTransferTemplate;
