import { useMemo } from 'react';
import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';
import Select from 'epn-ui/umd/dist/components/Select';
import Grid from 'epn-ui/umd/dist/components/Grid';
import Empty from 'epn-ui/umd/dist/components/Empty';

import BaseSanitizer from '@components/base/BaseSanitizer';

// Helpers
import useI18n from '@i18n';
import {
    bankCardValidation,
    cardExpValidation,
    getPaymentSystem,
    isNumber,
} from '../../WalletsForm/utils';
import { IWalletTemplateCities, IWalletTemplateForm } from '../interfaces';

// Config
import {
    MASTERCARD_COUNTRIES,
    PAYMENT_TYPES,
    regexpValidation,
    RENAME_ERRORS_BY_TYPE,
    VISA_COUNTRIES,
    walletsTypes,
} from '../../WalletsForm/config';

export interface IUsdBanksCardTemplateProps
    extends Pick<
        IWalletTemplateForm,
        | 'form'
        | 'countries'
        | 'countriesIsLoading'
        | 'walletType'
        | 'taxData'
        | 'isLoadingTaxData'
        | 'isOnlyForWireTransfer'
    > {
    cities: IWalletTemplateCities[];
    citiesIsLoading: boolean;
}

const BankCardUsdTemplate: React.FC<IUsdBanksCardTemplateProps> = ({
    form,
    countries,
    walletType,
    cities,
    countriesIsLoading,
    citiesIsLoading,
    isLoadingTaxData,
    taxData,
    isOnlyForWireTransfer,
}) => {
    const { t } = useI18n();

    const cardNumber = Form.useWatch('cardNumber', form);
    const paymentSystem = getPaymentSystem(cardNumber);

    if (
        !isLoadingTaxData &&
        !isOnlyForWireTransfer &&
        taxData[0]?.firstNameEn &&
        taxData[0]?.lastNameEn
    ) {
        form.setFieldsValue({ name: taxData[0].firstNameEn, surname: taxData[0].lastNameEn });
    }

    const allowedCountries = useMemo(() => {
        if (!paymentSystem || !countries) {
            return [];
        }

        if (paymentSystem === PAYMENT_TYPES.MASTERCARD) {
            return countries
                .filter(country => MASTERCARD_COUNTRIES.includes(country.value))
                .map(country => ({
                    ...country,
                    id: `ModalAddWalletCountrySelect_option_${country.value}`,
                }));
        }

        if (paymentSystem === PAYMENT_TYPES.VISA) {
            return countries
                .filter(country => VISA_COUNTRIES.includes(country.value))
                .map(country => ({
                    ...country,
                    id: `ModalAddWalletCountrySelect_option_${country.value}`,
                }));
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentSystem]);

    const citiesWithId = useMemo(() => {
        if (!Array.isArray(cities)) return [];
        if (cities.length === 0) return [];

        return cities.map(city => ({
            ...city,
            id: `ModalAddWalletCitySelect_option_${city.value}`,
        }));
    }, [cities]);

    return (
        <>
            <Form.Item
                name="walletName"
                label={t('Purse name')}
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletPurseNameInput"
                    maxLength={50}
                    placeholder={t('Enter purse name')}
                />
            </Form.Item>

            <Grid>
                <Grid.Col span={20}>
                    <Form.Item
                        label={t('Card number')}
                        name="cardNumber"
                        rules={[
                            {
                                validator: async (
                                    _rule: Rule,
                                    value: string | null | undefined,
                                ) => {
                                    if (value === null || value === undefined || value === '') {
                                        throw new Error(`${t('Required field')}`);
                                    }

                                    const result: string | boolean = bankCardValidation(value, t);

                                    if (typeof result !== 'boolean') {
                                        throw new Error(result);
                                    }
                                },
                                validateTrigger: 'onBlur',
                            },
                        ]}
                        getValueFromEvent={e => {
                            return e.unmaskedValue;
                        }}
                    >
                        <InputText
                            id="ModalAddWalletPurseNumberInput"
                            mask="0000 0000 0000 0000[ 000]"
                            onPaste={event => event.preventDefault()}
                            onChange={() => {
                                const selectedCounty = form.getFieldValue('country');
                                const selectedCity = form.getFieldValue('city');

                                if (selectedCounty) {
                                    form.resetFields(['country']);
                                }

                                if (selectedCity) {
                                    form.resetFields(['city']);
                                }
                            }}
                        />
                    </Form.Item>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Form.Item
                        label={t('MM/YY')}
                        name="cardExpire"
                        rules={[
                            {
                                validator: async (
                                    _rule: Rule,
                                    value: string | null | undefined,
                                ) => {
                                    if (value === null || value === undefined || value === '') {
                                        throw new Error(`${t('Required field')}`);
                                    }

                                    if (!regexpValidation.cardExpire.test(value)) {
                                        throw new Error(t('Invalid entire period of the card'));
                                    }

                                    const result: string | boolean = cardExpValidation(value, t);
                                    if (typeof result !== 'boolean') {
                                        throw new Error(result);
                                    }
                                },
                                validateTrigger: 'onBlur',
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletCardExpireInput"
                            maxLength={5}
                            mask="00{/}00"
                        />
                    </Form.Item>
                </Grid.Col>
            </Grid>

            <Grid wrap gutter={[16, 0]}>
                <Grid.Col span={12}>
                    <Form.Item
                        label={t('Name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('Required field'),
                                validateTrigger: 'onBlur',
                            },
                            {
                                pattern: regexpValidation.latin,
                                message: t('Only latin symbols'),
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletNameInput"
                            placeholder={t('Enter name')}
                            disabled={!isOnlyForWireTransfer}
                        />
                    </Form.Item>
                </Grid.Col>

                <Grid.Col span={12}>
                    <Form.Item
                        label={t('Surname')}
                        name="surname"
                        rules={[
                            {
                                required: true,
                                message: t('Required field'),
                                validateTrigger: 'onBlur',
                            },
                            {
                                pattern: regexpValidation.latin,
                                message: t('Only latin symbols'),
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletSurnameInput"
                            placeholder={t('Enter surname')}
                            disabled={!isOnlyForWireTransfer}
                        />
                    </Form.Item>
                </Grid.Col>
                {taxData && !isOnlyForWireTransfer && (
                    <Grid.Col>
                        <BaseSanitizer
                            html={t('preloaded tax data userName suggest')}
                            tag="p"
                            options={{ allowedTags: ['a'] }}
                            className="mb-4 text-p3 mt-[-15px] text-blueGray-300"
                        />
                    </Grid.Col>
                )}
            </Grid>

            <Form.Item
                label={t('Country')}
                name="country"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <Select
                    id="ModalAddWalletCountrySelect"
                    options={allowedCountries}
                    placeholder={t('Choose country')}
                    loading={countriesIsLoading}
                    disabled={countriesIsLoading}
                    onChange={() => {
                        const selectedCity = form.getFieldValue('city');

                        if (selectedCity) {
                            form.resetFields(['city']);
                        }
                    }}
                    notFoundContent={
                        <Empty
                            description={t(
                                'Enter your card number first to choose your country. The card must belong to either Visa or Mastercard payment systems',
                            )}
                        />
                    }
                />
            </Form.Item>

            <Form.Item
                label={t('City')}
                name="city"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <Select
                    id="ModalAddWalletCitySelect"
                    placeholder={t('Choose city')}
                    loading={citiesIsLoading}
                    disabled={citiesIsLoading}
                    options={citiesWithId}
                    notFoundContent={<Empty description={t('Choose country')} />}
                />
            </Form.Item>

            <Form.Item
                label={t('Address')}
                name="address"
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (value === null || value === undefined || value === '') {
                                throw new Error(`${t('Required field')}`);
                            }

                            if (
                                walletType === walletsTypes.bankCardUkraine &&
                                value.match(/(^|\s)(крым|Крым|Crimea|crimea)/g)
                            ) {
                                throw new Error(
                                    t('Unfortunately, the payout cannot be made to the Crimea'),
                                );
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText id="ModalAddWalletAddressInput" placeholder={t('Enter Address')} />
            </Form.Item>

            <Form.Item
                label={t('Zip code')}
                name="index"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletCityIndexInput"
                    placeholder={t('Enter zip code')}
                    maxLength={10}
                />
            </Form.Item>

            <div className="mb-4 text-blueGray-600 text-p2">
                * {t('Withdrawals to Mastercard cards are available in the following countries:')}
                <br />
                {t('Сountries where Mastercard is available')}
            </div>
            <div className="mb-4 text-blueGray-600 text-p2">
                * {t('Withdrawals to Visa cards are available in the following countries:')}
                <br />
                {t('Сountries where Visa is available')}
            </div>
        </>
    );
};

export default BankCardUsdTemplate;
