import Form, { Rule } from 'epn-ui/umd/dist/components/Form';
import InputText from 'epn-ui/umd/dist/components/InputText';
import Grid from 'epn-ui/umd/dist/components/Grid';

import BaseSanitizer from '@components/base/BaseSanitizer';

// Helpers
import useI18n from '@i18n';
import { bankCardValidation, cardExpValidation, isNumber } from '../../WalletsForm/utils';
import { IWalletTemplateForm } from '../interfaces';

// Config
import { regexpValidation, RENAME_ERRORS_BY_TYPE, walletsTypes } from '../../WalletsForm/config';

export type ICisBanksCardsTemplateProps = Pick<
    IWalletTemplateForm,
    'walletType' | 'form' | 'isLoadingTaxData' | 'taxData' | 'isOnlyForWireTransfer'
>;

const CisBanksCardsTemplate: React.FC<ICisBanksCardsTemplateProps> = ({
    form,
    walletType,
    isLoadingTaxData,
    taxData,
    isOnlyForWireTransfer,
}) => {
    const { t } = useI18n();

    const preloadedCardHolderName =
        !isLoadingTaxData &&
        !isOnlyForWireTransfer &&
        taxData[0]?.lastNameEn &&
        taxData[0]?.firstNameEn &&
        `${taxData[0].lastNameEn} ${taxData[0].firstNameEn}`.trim();

    if (preloadedCardHolderName) {
        form.setFieldsValue({ userName: preloadedCardHolderName });
    }

    return (
        <>
            <Form.Item
                name="walletName"
                label={t('Purse name')}
                rules={[
                    {
                        validator: async (_rule: Rule, value: string | null | undefined) => {
                            if (isNumber(value)) {
                                throw new Error(t(RENAME_ERRORS_BY_TYPE.notNumber));
                            }
                        },
                        validateTrigger: 'onBlur',
                    },
                ]}
            >
                <InputText
                    id="ModalAddWalletPurseNameInput"
                    placeholder={t('Enter purse name')}
                    maxLength={50}
                />
            </Form.Item>

            <Grid>
                <Grid.Col span={20}>
                    <Form.Item
                        label={t('Card number')}
                        name="cardNumber"
                        rules={[
                            {
                                validator: async (
                                    _rule: Rule,
                                    value: string | null | undefined,
                                ) => {
                                    if (value === null || value === undefined || value === '') {
                                        throw new Error(`${t('Required field')}`);
                                    }

                                    const result: string | boolean = bankCardValidation(value, t);

                                    if (typeof result !== 'boolean') {
                                        throw new Error(result);
                                    }
                                },
                                validateTrigger: 'onBlur',
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletPurseNumberInput"
                            mask="0000 0000 0000 0000[ 000]"
                            onPaste={event => event.preventDefault()}
                        />
                    </Form.Item>
                </Grid.Col>
                <Grid.Col span={4}>
                    <Form.Item
                        label={t('MM/YY')}
                        name="cardExpire"
                        rules={[
                            {
                                validator: async (
                                    _rule: Rule,
                                    value: string | null | undefined,
                                ) => {
                                    if (value === null || value === undefined || value === '') {
                                        throw new Error(`${t('Required field')}`);
                                    }

                                    if (!regexpValidation.cardExpire.test(value)) {
                                        throw new Error(t('Invalid entire period of the card'));
                                    }

                                    const result: string | boolean = cardExpValidation(value, t);
                                    if (typeof result !== 'boolean') {
                                        throw new Error(result);
                                    }
                                },
                                validateTrigger: 'onBlur',
                            },
                        ]}
                    >
                        <InputText
                            id="ModalAddWalletCardExpireInput"
                            maxLength={5}
                            mask="00{/}00"
                        />
                    </Form.Item>
                </Grid.Col>
            </Grid>

            <Form.Item
                label={t('Card owner')}
                name="userName"
                rules={[
                    {
                        required: true,
                        message: t('Required field'),
                        validateTrigger: 'onBlur',
                    },
                    // {
                    //     pattern: regexpValidation.latin,
                    //     message: t('Only latin symbols'),
                    // },
                ]}
            >
                <InputText
                    id="ModalAddWalletCardOwnerInput"
                    placeholder={t('Enter card owner')}
                    disabled={!isOnlyForWireTransfer}
                />
            </Form.Item>
            {taxData && !isOnlyForWireTransfer && (
                <BaseSanitizer
                    html={t('preloaded tax data userName suggest')}
                    tag="p"
                    options={{ allowedTags: ['a'] }}
                    className="mb-4 text-p3 mt-[-15px] text-blueGray-300"
                />
            )}

            {walletType === walletsTypes.bankCardUkraineUah && (
                <div className="mb-4 text-blueGray-600 text-p2">
                    {t(
                        'Only for cards issued by Ukrainian banks. Payment execution currency: hryvnia (UAH)',
                    )}
                </div>
            )}

            {walletType === walletsTypes.bankCardKazakhstan && (
                <div className="mb-4 text-blueGray-600 text-p2">
                    {t('Only for cards issued by banks of Kazakhstan')}
                </div>
            )}
        </>
    );
};

export default CisBanksCardsTemplate;
